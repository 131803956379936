import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { SMI_ONE_CARD_HOME_URL } from "../../constants/pageUrls"

const TermsPage = () => (
  <Layout>
    <SEO title="PayPerks Legal Pages - Terms and Conditions" />
    <div className="legal-page corp-legal-terms">
      <div className="content-wrap max-640">
        <h1 className="">PayPerks General Terms &amp; Conditions</h1>
        <hr className="hairline green1 mt-3 mb-3" />
        <div className="section mb-5">
          <p className="intro-p">
            PayPerks will be integrated into the smiONE<sup>&trade;</sup> Visa
            <sup>&reg;</sup> Prepaid Card program. Terms of Service, Privacy
            Policy, and future Sweepstakes Rules will be available on the{" "}
            <a
              href={SMI_ONE_CARD_HOME_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              smiONE
              <sup>&trade;</sup> Card website
            </a>
            .
          </p>
        </div>
        <div className="date-mark">
          <p className="ts-s upper">
            <strong>Date last modified: June 30, 2021</strong>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsPage
